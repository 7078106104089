<template>
  <div class="ls-page">
    <div class="ps-section__header ls-page-header">
      <h1 class="container ls-page-header-title">Privacy Policy</h1>
      <h3 class="container ls-page-header-subtitle"></h3>
    </div>
    <div class="ps-section__content ls-page-content">
      <div class="row">
        <div class="col-12">
          <h2
            style="padding-top: 3pt;padding-left: 5pt;text-indent: 0pt;line-height: 21pt;text-align: left;"
          >
            Terms and Conditions of Service
          </h2>
          <p
            class="s6"
            style="padding-left: 5pt;text-indent: 0pt;line-height: 114%;text-align: left;"
          >
            Please take a moment to read these Pahukama Terms of Service
            including our policies (&quot;Terms&quot;) to understand how they
            apply to your use of our website and any products or services that
            you may order and purchase online. Please read the rules carefully.
            Use of and/or registration on any aspect of Pahukama will constitute
            your agreement to comply with these rules and to the Terms and
            Conditions. If you cannot agree with these rules or Terms and
            Conditions, please do not use this website.
          </p>
          <p style="text-indent: 0pt;text-align: left;"><br /></p>
          <h3 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
            General Terms And Conditions
          </h3>
          <p
            style="padding-top: 13pt;padding-left: 5pt;text-indent: 0pt;text-align: left;"
          >
            This website is owned and operated by Pahukama Pvt Ltd, a company
            registered in Massachusetts, which has its registered office in
            Waltham. This website is provided to you free of charge for your
            personal use subject to these Terms and Conditions and our Privacy
            Policy. All rights and/or defenses afforded to us in these Terms
            shall stand to the benefit of Pahukama.
          </p>
          <p style="text-indent: 0pt;text-align: left;"><br /></p>
          <ol id="l2">
            <ol id="l3">
              <li data-list-text="1.1">
                <p
                  class="s7"
                  style="padding-left: 26pt;text-indent: -22pt;text-align: left;"
                >
                  How our Terms and Conditions apply to you
                </p>
                <p
                  style="padding-top: 14pt;padding-left: 5pt;text-indent: 0pt;text-align: left;"
                >
                  These Terms and Conditions apply to your use of this website
                  and by using it, you acknowledge that you have read and
                  understood these Terms and Conditions and agree to be bound by
                  them. They represent our entire agreement with you and
                  supersede all prior terms, conditions, warranties, and/or
                  representations to the extent permitted by law. By accessing
                  this website and/or placing an order or requesting a delivery
                  you agree to be bound by the Terms and Conditions set out
                  below.
                </p>
                <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
                  Please do not use our website if you do not agree to be bound
                  by these Terms. Online purchases from our website are subject
                  to these Terms and Conditions. Any questions regarding these
                  Terms and Conditions or subsequent issues should be directed
                  to Pahukama Online Shopping.
                </p>
                <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
                  Alternatively, you may also contact us here. Please read these
                  Terms and Conditions carefully and print and keep a copy of
                  them for your reference.
                </p>
                <p style="text-indent: 0pt;text-align: left;"><br /></p>
              </li>
              <li data-list-text="1.2">
                <p
                  class="s7"
                  style="padding-left: 26pt;text-indent: -22pt;text-align: left;"
                >
                  Updates, amendments, and changes to our Terms
                </p>
                <p
                  style="padding-top: 14pt;padding-left: 5pt;text-indent: 0pt;text-align: left;"
                >
                  We may periodically update or change the Terms and Conditions
                  without notice. You should check them from time to time, as
                  your continued use of our website will mean you accept any
                  updated or revised Terms and Conditions. The date from which
                  the current set of Terms and Conditions came into effect is
                  included within the Terms and Conditions. Do not assume that
                  the same Terms and Conditions will apply to future orders or
                  delivery requests. We reserve the right to: Modify or
                  withdraw, temporarily or permanently, this website (or any
                  part thereof) with or without notice to you and you confirm
                  that we shall not be liable to you or any third party for any
                  modification to or withdrawal of the website: Change the Terms
                  and Conditions from time to time, and your continued use of
                  the website (or any part thereof) following such change shall
                  be deemed to be your acceptance of such change. It is your
                  responsibility to check regularly to determine whether the
                  Terms and Conditions have been changed. If you do not agree to
                  any change to the Terms and Conditions then you must
                  immediately stop using our website.
                </p>
              </li>
              <li data-list-text="1.3">
                <p
                  class="s7"
                  style="padding-top: 3pt;padding-left: 26pt;text-indent: -22pt;text-align: left;"
                >
                  We are governed by Massachusetts Law and Arbitration
                </p>
                <p
                  style="padding-top: 14pt;padding-left: 5pt;text-indent: 0pt;text-align: left;"
                >
                  This agreement is governed by Massachusetts Law and
                  Arbitration. Any dispute arising in relation to our agreement
                  with you shall, to the extent permitted by law, be referred to
                  arbitration in Massachusetts at a venue to be determined by
                  us.
                </p>
                <p style="text-indent: 0pt;text-align: left;"><br /></p>
              </li>
              <li data-list-text="1.4">
                <p
                  class="s7"
                  style="padding-left: 26pt;text-indent: -22pt;text-align: left;"
                >
                  Who supplies your goods and services
                </p>
                <p
                  style="padding-top: 14pt;padding-left: 5pt;text-indent: 0pt;text-align: left;"
                >
                  Unless stated to the contrary on our website, the goods and
                  services supplied in accordance with any contract made through
                  our website are supplied by the various merchants. As part of
                  our delivery service, we may engage the use of 3rd party
                  delivery providers to fulfill services on our behalf. We sell
                  products and services on behalf of 3rd parties, and this is
                  clearly indicated as such.
                </p>
                <p style="text-indent: 0pt;text-align: left;"><br /></p>
              </li>
              <li data-list-text="1.5">
                <p
                  class="s7"
                  style="padding-left: 26pt;text-indent: -22pt;text-align: left;"
                >
                  Using our Website
                </p>
                <p
                  style="padding-top: 14pt;padding-left: 5pt;text-indent: 0pt;text-align: left;"
                >
                  Merchant delivery and &quot;Pick Up&quot; orders placed by
                  using this website, along with access to them, are granted
                  strictly in accordance with these Terms and Conditions.
                  Pahukama has taken every care in the preparation of the
                  content of this website. However, to the extent permitted by
                  applicable law, Pahukama does not represent or warrant the
                  accuracy or reliability of any of the information or content
                  about any goods or services, software, or advertisements
                  (together, the &quot;materials&quot;) contained on,
                  distributed through, or linked, downloaded or accessed from
                  this website.
                </p>
                <p style="text-indent: 0pt;text-align: left;"><br /></p>
                <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
                  To the fullest extent permitted by applicable laws, Pahukama
                  hereby excludes liability for any claims, losses, demands, or
                  damages of any kind whatsoever with respect to the website or
                  the Materials including, without limitation, direct, indirect,
                  incidental, or consequential losses, or damages, whether
                  arising from loss of profits, loss of revenue, loss of data,
                  loss of use or otherwise and whether or not the possibility of
                  such loss has been notified to Pahukama. The foregoing will
                  apply whether such claims, loss, or damages arise in tort,
                  contract, negligence, under the statute, or otherwise.
                </p>
                <p
                  style="padding-left: 5pt;text-indent: 0pt;line-height: 12pt;text-align: left;"
                >
                  However, if you are a consumer your statutory rights are not
                  affected.
                </p>
                <p style="text-indent: 0pt;text-align: left;"><br /></p>
                <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
                  You are welcome to browse or use our website for your own
                  personal, non-commercial shopping and information purposes
                  only. No other use is permitted without our prior written
                  consent. The unauthorized use, copying, reproduction,
                  variation, modification, or distribution of the content of the
                  Website, the uploading of any unlawful or damaging information
                  or viral software, is strictly prohibited.
                </p>
                <p style="text-indent: 0pt;text-align: left;"><br /></p>
              </li>
              <li data-list-text="1.6">
                <p
                  class="s7"
                  style="padding-left: 26pt;text-indent: -22pt;text-align: left;"
                >
                  Using our Website from abroad
                </p>
                <p
                  style="padding-top: 14pt;padding-left: 5pt;text-indent: 0pt;text-align: left;"
                >
                  Customers who are not residents in Zimbabwe are welcome to use
                  our website in accordance with these Terms and Conditions to
                  make online purchases provided that the delivery address is in
                  Zimbabwe or the authorized collection agent is in Zimbabwe.
                  Pahukama makes no promise that materials on the Website are
                  appropriate or available for use in locations outside the
                  Republic of Zimbabwe, and accessing the website from
                  territories where its contents are illegal or unlawful is
                  prohibited. If you choose to access this site from locations
                  outside the Republic of Zimbabwe, you do on your own
                  initiative and are responsible for compliance with local laws.
                </p>
              </li>
              <li data-list-text="1.7">
                <p
                  class="s7"
                  style="padding-top: 4pt;padding-left: 26pt;text-indent: -22pt;text-align: left;"
                >
                  Ownership and use of our Intellectual Property
                </p>
                <p
                  style="padding-top: 14pt;padding-left: 5pt;text-indent: 0pt;text-align: left;"
                >
                  All rights, including copyright, trademark, and other
                  intellectual property rights embodied in any logos, text,
                  images, video, audio, or other material on this Website are
                  owned by or licensed to us. All data and information
                  communicated to or from the website including its database
                  belong solely to us or our licensors. You agree to sign all
                  documents as we may reasonably require in order to assign any
                  rights that you may acquire in the content of our website. You
                  agree also to waive any moral rights in such content. You are
                  permitted to view, print or store electronically a copy of any
                  information on our website, including these Terms and
                  Conditions, solely for your personal, lawful, non-commercial
                  use. Unauthorized use, reproduction, modification, and/or
                  distribution is strictly prohibited and constitutes an
                  unlawful infringement of our intellectual property rights. You
                  may not otherwise reproduce, modify, copy or distribute or use
                  for commercial purposes any of the materials or content on the
                  Website without written permission from Pahukama.
                </p>
                <p style="text-indent: 0pt;text-align: left;"><br /></p>
              </li>
              <li data-list-text="1.8">
                <p
                  class="s7"
                  style="padding-left: 26pt;text-indent: -22pt;text-align: left;"
                >
                  Disclaimers and limitations of our liability
                </p>
                <p
                  style="padding-top: 14pt;padding-left: 5pt;text-indent: 0pt;text-align: left;"
                >
                  Use of our website is entirely at your own risk. We make no
                  representations or warranties of any kind, whether express or
                  implied. We do not warrant that the functions provided by the
                  website will be uninterrupted or error-free, or that the
                  website or the servers that make them available are free from
                  viruses or other harmful components. We accept no liability,
                  to the extent permitted by law, for any direct, indirect,
                  incidental, special, or consequential loss or damage of any
                  kind whatsoever or howsoever caused arising from the access or
                  use of our website or the purchase of any of our products or
                  services. You agree, to the extent permitted by law, to
                  indemnify Pahukama against any loss or damage suffered or
                  liability incurred by reason of any act or omission on your
                  part or that of any third party acting on your behalf in
                  connection with your use of our website and/or your purchase
                  of any products or services and including the use of any
                  delivery services. Notwithstanding any other provisions
                  contained herein, our liability arising from any breach of
                  these Terms, negligence, or otherwise shall not at any time
                  exceed more than double the monetary value of any order placed
                  via this website. Pahukama will not be responsible for any
                  errors or omissions or for the results obtained from the use
                  of such information or for any technical problems you may
                  experience with the website. If Pahukama is informed of any
                  inaccuracies in the material on the site we will attempt to
                  correct the inaccuracies as soon as we reasonably can.
                </p>
                <p style="text-indent: 0pt;text-align: left;"><br /></p>
              </li>
              <li data-list-text="1.9">
                <p
                  class="s1"
                  style="padding-left: 26pt;text-indent: -22pt;text-align: left;"
                >
                  Content being distributed from our Website
                </p>
                <p
                  style="padding-top: 14pt;padding-left: 5pt;text-indent: 0pt;text-align: left;"
                >
                  All information or content transmitted by online invitations,
                  e-cards, or other mechanisms provided for, by, or through the
                  Pahukama website is your responsibility as the sender. You
                  confirm that by using these services you will not transmit any
                  material which could be considered offensive, obscene, or
                  otherwise objectionable. Pahukama cannot be held liable either
                  directly or indirectly for the content of any customer content
                  distribution mechanism, nor for any loss nor damage of any
                  kind which you may incur as the result of your use of this
                  service. You hereby agree to indemnify Pahukama for any such
                  loss which may have occurred.
                </p>
                <p style="text-indent: 0pt;text-align: left;"><br /></p>
              </li>
              <li data-list-text="1.10">
                <p
                  class="s7"
                  style="padding-left: 34pt;text-indent: -29pt;text-align: left;"
                >
                  Linking from Pahukama to other websites
                </p>
              </li>
            </ol>
          </ol>
          <p
            style="padding-top: 14pt;padding-left: 5pt;text-indent: 0pt;text-align: left;"
          >
            Pahukama aims to include links to other websites that provide
            relevant and useful information for people interested in topics
            related, but not limited to, Pahukama&#39;s general activities. We
            do not link to individual personal websites and will not knowingly
            provide links to sites that contain illegal, offensive, or
            misleading information. Where an organization has regional or local
            services and
          </p>
          <p
            style="padding-top: 3pt;padding-left: 5pt;text-indent: 0pt;text-align: left;"
          >
            <a
              href="http://www.pahukama.com/"
              style=" color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 10.5pt;"
              target="_blank"
              >associated websites, we will link to their national website.
              Wherever possible we aim to link to our website to ensure
              information is appropriate and relevant to our customers. Links
              will be provided at the discretion of </a
            ><a href="http://www.pahukama.com/" class="s8" target="_blank"
              >www.pahukama.com</a
            >
          </p>
          <p style="text-indent: 0pt;text-align: left;"><br /></p>
          <h4
            style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: left;"
          >
            SUMMARY OF KEY POINTS
          </h4>
          <p style="text-indent: 0pt;text-align: left;"><br /></p>
          <p
            class="s9"
            style="padding-left: 5pt;text-indent: 0pt;line-height: 114%;text-align: left;"
          >
            <a href="https://taura-c9f8e.web.app/#toc" class="a" target="_blank"
              >This summary provides key points from our privacy notice, but you
              can find out more details about any of these topics by clicking
              the link following each key point or by using our table of
              contents below to find the section you are looking for. You can
              also click </a
            ><span
              style=' color: #212121; font-family:"Times New Roman", serif; font-style: normal; font-weight: normal; text-decoration: underline; font-size: 13.5pt;'
              >here</span
            >
            to go directly to our table of contents.
          </p>
          <p style="text-indent: 0pt;text-align: left;"><br /></p>
          <h4
            style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;line-height: 113%;text-align: left;"
          >
            What personal information do we process?
            <span class="s9"
              >When you visit, use, or navigate our Services, we may process
              personal information depending on how you interact</span
            >
          </h4>
          <p
            class="s9"
            style="padding-left: 5pt;text-indent: 0pt;line-height: 115%;text-align: left;"
          >
            with Taura and the Services, the choices you make, and the products
            and features you use. .
          </p>
          <p style="text-indent: 0pt;text-align: left;"><br /></p>
          <h4
            style="padding-left: 5pt;text-indent: 0pt;line-height: 115%;text-align: left;"
          >
            Do we process any sensitive personal information?
            <span class="s9"
              >We do not process sensitive personal information.</span
            >
          </h4>
          <p style="text-indent: 0pt;text-align: left;"><br /></p>
          <h4
            style="padding-left: 5pt;text-indent: 0pt;line-height: 115%;text-align: left;"
          >
            Do we receive any information from third parties?
            <span class="s9"
              >We do not receive any information from third parties.</span
            >
          </h4>
          <p style="text-indent: 0pt;text-align: left;"><br /></p>
          <h4
            style="padding-left: 5pt;text-indent: 0pt;line-height: 114%;text-align: left;"
          >
            How do we process your information?
            <span class="s9"
              >We process your information to provide, improve, and administer
              our Services, communicate with you, for security and fraud
              prevention, and to comply with law. We may also process your
              information for other purposes with your consent. We process your
              information only when we have a valid legal reason to do so.</span
            >
          </h4>
          <p style="text-indent: 0pt;text-align: left;"><br /></p>
          <p
            class="s11"
            style="padding-left: 5pt;text-indent: 0pt;text-align: left;"
          >
            Information Collection and Use
          </p>
          <p
            class="s12"
            style="padding-top: 3pt;padding-left: 5pt;text-indent: 0pt;text-align: left;"
          >
            For a better experience, while using our Service, we may require you
            to provide us with certain personally identifiable information,
            including but not limited to Device ID, contact information, usage
            statistics. The information that we request will be retained by us
            and used as described in this privacy policy.
          </p>
          <p style="text-indent: 0pt;text-align: left;"><br /></p>
          <p
            class="s11"
            style="padding-left: 5pt;text-indent: 0pt;text-align: left;"
          >
            Personal information you disclose to us
          </p>
          <p style="text-indent: 0pt;text-align: left;"><br /></p>
          <p
            class="s13"
            style="padding-left: 5pt;text-indent: 0pt;text-align: left;"
          >
            In Short: We collect personal information that you provide to us.
          </p>
          <p style="text-indent: 0pt;text-align: left;"><br /></p>
          <p
            class="s12"
            style="padding-left: 5pt;text-indent: 0pt;line-height: 114%;text-align: justify;"
          >
            We collect personal information that you voluntarily provide to us
            when you register on the Services, express an interest in obtaining
            information about us or our products and Services, when you
            participate in activities on the Services, or otherwise when you
            contact us.
          </p>
          <p style="text-indent: 0pt;text-align: left;"><br /></p>
          <p
            class="s11"
            style="padding-left: 5pt;text-indent: 0pt;line-height: 114%;text-align: left;"
          >
            Personal Information Provided by You.
            <span class="s12"
              >The personal information that we collect depends on the context
              of your interactions with us and the Services, the choices you
              make, and the products and features you use. The personal
              information we collect may include the following:</span
            >
          </p>
          <p style="text-indent: 0pt;text-align: left;"><br /></p>
          <ul id="l4">
            <li data-list-text="">
              <p
                class="s14"
                style="padding-left: 41pt;text-indent: -18pt;text-align: left;"
              >
                phone numbers
              </p>
              <p style="text-indent: 0pt;text-align: left;"><br /></p>
            </li>
            <li data-list-text="">
              <p
                class="s14"
                style="padding-left: 41pt;text-indent: -18pt;text-align: left;"
              >
                email addresses
              </p>
              <p style="text-indent: 0pt;text-align: left;"><br /></p>
            </li>
            <li data-list-text="">
              <p
                class="s14"
                style="padding-left: 41pt;text-indent: -18pt;text-align: left;"
              >
                passwords
              </p>
              <p style="text-indent: 0pt;text-align: left;"><br /></p>
            </li>
            <li data-list-text="">
              <p
                class="s14"
                style="padding-left: 41pt;text-indent: -18pt;text-align: left;"
              >
                usernames
              </p>
              <p style="text-indent: 0pt;text-align: left;"><br /></p>
            </li>
            <li data-list-text="">
              <p
                class="s14"
                style="padding-left: 41pt;text-indent: -18pt;text-align: left;"
              >
                contact or authentication data
              </p>
              <p style="text-indent: 0pt;text-align: left;"><br /></p>
            </li>
            <li data-list-text="">
              <p
                class="s14"
                style="padding-left: 41pt;text-indent: -18pt;text-align: left;"
              >
                billing addresses
              </p>
              <p style="text-indent: 0pt;text-align: left;"><br /></p>
            </li>
            <li data-list-text="">
              <p
                class="s14"
                style="padding-left: 41pt;text-indent: -18pt;text-align: left;"
              >
                debit/credit card numbers
              </p>
              <p style="text-indent: 0pt;text-align: left;"><br /></p>
              <p
                class="s11"
                style="padding-left: 5pt;text-indent: 0pt;text-align: left;"
              >
                Sensitive Information.
                <span class="s12"
                  >We do not process sensitive information.</span
                >
              </p>
              <p style="text-indent: 0pt;text-align: left;"><br /></p>
              <p
                class="s11"
                style="padding-left: 5pt;text-indent: 0pt;line-height: 114%;text-align: left;"
              >
                Payment Data.
                <span class="s12"
                  >We may collect data necessary to process your payment if you
                  make purchases, such as your payment instrument number (such
                  as a credit card number), and the security code associated
                  with your payment instrument. All payment data is stored by
                  Taura. You may find their privacy notice link(s) here:
                  privacy.tauraonline.com.</span
                >
              </p>
              <p
                class="s11"
                style="padding-top: 5pt;padding-left: 5pt;text-indent: 0pt;line-height: 114%;text-align: left;"
              >
                Application Data.
                <span class="s12"
                  >If you use our application(s), we also may collect the
                  following information if you choose to provide us with access
                  or permission:</span
                >
              </p>
              <p style="text-indent: 0pt;text-align: left;"><br /></p>
            </li>
            <li data-list-text="">
              <p
                class="s15"
                style="padding-left: 41pt;text-indent: -18pt;line-height: 114%;text-align: left;"
              >
                Geolocation Information.
                <span class="s14"
                  >We may request access or permission to track location-based
                  information from your mobile device, either continuously or
                  while you are using our mobile application(s), to provide
                  certain location-based services. If you wish to change our
                  access or permissions, you may do so in your device&#39;s
                  settings.</span
                >
              </p>
              <p style="text-indent: 0pt;text-align: left;"><br /></p>
            </li>
            <li data-list-text="">
              <p
                class="s15"
                style="padding-left: 41pt;text-indent: -18pt;line-height: 114%;text-align: left;"
              >
                Mobile Device Access.
                <span class="s14"
                  >We may request access or permission to certain features from
                  your mobile device, including your mobile</span
                >
              </p>
              <p
                class="s14"
                style="padding-left: 41pt;text-indent: 0pt;line-height: 113%;text-align: left;"
              >
                device&#39;s bluetooth, calendar, camera, microphone, contacts,
                sensors, sms messages, storage, and other features. If you wish
                to change our access or permissions, you may do so in your
                device&#39;s settings.
              </p>
              <p style="text-indent: 0pt;text-align: left;"><br /></p>
            </li>
            <li data-list-text="">
              <p
                class="s15"
                style="padding-left: 41pt;text-indent: -18pt;line-height: 114%;text-align: left;"
              >
                Mobile Device Data.
                <span class="s14"
                  >We automatically collect device information (such as your
                  mobile device ID, model, and manufacturer), operating system,
                  version information and system configuration information,
                  device and application identification numbers, browser type
                  and version, hardware model Internet service provider and/or
                  mobile carrier, and Internet Protocol (IP) address (or proxy
                  server). If you are using our application(s), we may also
                  collect information about the phone network associated with
                  your mobile device, your mobile device’s operating system or
                  platform, the type of mobile device you use, your mobile
                  device’s unique device ID, and information about the features
                  of our application(s) you accessed.</span
                >
              </p>
              <p style="text-indent: 0pt;text-align: left;"><br /></p>
            </li>
            <li data-list-text="">
              <p
                class="s15"
                style="padding-left: 41pt;text-indent: -18pt;line-height: 114%;text-align: left;"
              >
                Push Notifications.
                <span class="s14"
                  >We may request to send you push notifications regarding your
                  account or certain features of the application(s). If you wish
                  to opt out from receiving these types of communications, you
                  may turn them off in your device&#39;s settings.</span
                >
              </p>
              <p style="text-indent: 0pt;text-align: left;"><br /></p>
              <p
                class="s12"
                style="padding-left: 5pt;text-indent: 0pt;line-height: 114%;text-align: left;"
              >
                This information is primarily needed to maintain the security
                and operation of our application(s), for troubleshooting, and
                for our internal analytics and reporting purposes.
              </p>
              <p style="text-indent: 0pt;text-align: left;"><br /></p>
              <p
                class="s12"
                style="padding-left: 5pt;text-indent: 0pt;line-height: 114%;text-align: left;"
              >
                All personal information that you provide to us must be true,
                complete, and accurate, and you must notify us of any changes to
                such personal information.
              </p>
              <p style="text-indent: 0pt;text-align: left;"><br /></p>
              <p
                class="s11"
                style="padding-left: 5pt;text-indent: 0pt;text-align: left;"
              >
                Information automatically collected
              </p>
              <p style="text-indent: 0pt;text-align: left;"><br /></p>
              <p
                class="s13"
                style="padding-left: 5pt;text-indent: 0pt;line-height: 114%;text-align: left;"
              >
                In Short: Some information — such as your Internet Protocol (IP)
                address and/or browser and device characteristics — is collected
                automatically when you visit our Services.
              </p>
              <p
                class="s12"
                style="padding-top: 5pt;padding-left: 5pt;text-indent: 0pt;line-height: 114%;text-align: left;"
              >
                We automatically collect certain information when you visit,
                use, or navigate the Services. This information does not reveal
                your specific identity (like your name or contact information)
                but may include device and usage information, such as your IP
                address, browser and device characteristics, operating system,
                language preferences, referring URLs, device name, country,
                location, information about how and when you use our Services,
                and other technical information. This information is primarily
                needed to maintain the security and operation of our Services,
                and for our internal analytics and reporting purposes.
              </p>
              <p style="text-indent: 0pt;text-align: left;"><br /></p>
              <p
                class="s12"
                style="padding-left: 5pt;text-indent: 0pt;text-align: left;"
              >
                Like many businesses, we also collect information through
                cookies and similar technologies.
              </p>
              <p style="text-indent: 0pt;text-align: left;"><br /></p>
              <p
                class="s12"
                style="padding-left: 5pt;text-indent: 0pt;text-align: left;"
              >
                The information we collect includes:
              </p>
              <p style="text-indent: 0pt;text-align: left;"><br /></p>
            </li>
            <li data-list-text="">
              <p
                class="s15"
                style="padding-left: 41pt;text-indent: -18pt;text-align: left;"
              >
                Device Id . We collect information for analytics and statistcal
                usages.
              </p>
            </li>
            <li data-list-text="">
              <p
                class="s15"
                style="padding-top: 1pt;padding-left: 41pt;text-indent: -18pt;text-align: left;"
              >
                Contact deatails. Collected for marketing, analytics and
                developer communications.
              </p>
            </li>
            <li data-list-text="">
              <p
                class="s15"
                style="padding-top: 2pt;padding-left: 41pt;text-indent: -18pt;line-height: 114%;text-align: left;"
              >
                Log and Usage Data.
                <span class="s14"
                  >Log and usage data is service-related, diagnostic, usage, and
                  performance information our servers automatically collect when
                  you access or use our Services and which we record in log
                  files. Depending on how you interact with us, this log data
                  may include your IP address, device information, browser type,
                  and settings and information about your activity in the
                  Services (such as the date/time stamps associated with your
                  usage, pages and files viewed, searches, and other actions you
                  take such as which features you use), device event information
                  (such as system activity, error reports (sometimes called
                  &quot;crash dumps&quot;), and hardware settings).</span
                >
              </p>
              <p style="text-indent: 0pt;text-align: left;"><br /></p>
            </li>
            <li data-list-text="">
              <p
                class="s15"
                style="padding-left: 41pt;text-indent: -18pt;line-height: 114%;text-align: left;"
              >
                Device Data.
                <span class="s14"
                  >We collect device data such as information about your
                  computer, phone, tablet, or other device you use to access the
                  Services. Depending on the device used, this device data may
                  include information such as your IP address (or proxy server),
                  device and application identification numbers, location,
                  browser type, hardware model, Internet service provider and/or
                  mobile carrier, operating system, and system configuration
                  information.</span
                >
              </p>
              <p style="text-indent: 0pt;text-align: left;"><br /></p>
            </li>
            <li data-list-text="">
              <p
                class="s15"
                style="padding-left: 41pt;text-indent: -18pt;line-height: 114%;text-align: left;"
              >
                Location Data.
                <span class="s14"
                  >We collect location data such as information about your
                  device&#39;s location, which can be either precise or
                  imprecise. How much information we collect depends on the type
                  and settings of the device you use to access the Services. For
                  example, we may use GPS and other technologies to collect
                  geolocation data that tells us your current location (based on
                  your IP address). You can opt out of allowing us to collect
                  this information either by refusing access to the information
                  or by disabling your Location setting on your device. However,
                  if you choose to opt out, you may not be able to use certain
                  aspects of the Services.</span
                >
              </p>
              <p style="text-indent: 0pt;text-align: left;"><br /></p>
              <p
                class="s13"
                style="padding-left: 5pt;text-indent: 0pt;line-height: 114%;text-align: left;"
              >
                In Short: We process your information to provide, improve, and
                administer our Services, communicate with you, for security and
                fraud prevention, and to comply with law. We may also process
                your information for other purposes with your consent.
              </p>
              <p
                class="s11"
                style="padding-top: 5pt;padding-left: 5pt;text-indent: 0pt;line-height: 114%;text-align: left;"
              >
                We process your personal information for a variety of reasons,
                depending on how you interact with our Services, including:
              </p>
              <p style="text-indent: 0pt;text-align: left;"><br /></p>
            </li>
            <li data-list-text="">
              <p
                class="s16"
                style="padding-left: 41pt;text-indent: -18pt;line-height: 114%;text-align: justify;"
              >
                To facilitate account creation and authentication and otherwise
                manage user accounts.
                <span class="s14"
                  >We may process your information so you can create and log in
                  to your account, as well as keep your account in working
                  order.</span
                >
              </p>
              <p style="text-indent: 0pt;text-align: left;"><br /></p>
            </li>
            <li data-list-text="">
              <p
                class="s16"
                style="padding-left: 41pt;text-indent: -18pt;line-height: 113%;text-align: left;"
              >
                To respond to user inquiries/offer support to users.
                <span class="s14"
                  >We may process your information to respond to your inquiries
                  and solve any potential issues you might have with the
                  requested service.</span
                >
              </p>
              <p style="text-indent: 0pt;text-align: left;"><br /></p>
            </li>
            <li data-list-text="">
              <p
                class="s16"
                style="padding-left: 41pt;text-indent: -18pt;line-height: 114%;text-align: left;"
              >
                To fulfill and manage your orders.
                <span class="s14"
                  >We may process your information to fulfill and manage your
                  orders, payments, returns, and exchanges made through the
                  Services.</span
                >
              </p>
              <p style="text-indent: 0pt;text-align: left;"><br /></p>
            </li>
            <li data-list-text="">
              <p
                class="s16"
                style="padding-left: 41pt;text-indent: -18pt;line-height: 114%;text-align: left;"
              >
                To enable user-to-user communications.
                <span class="s14"
                  >We may process your information if you choose to use any of
                  our offerings that allow for communication with another
                  user.</span
                >
              </p>
              <p style="text-indent: 0pt;text-align: left;"><br /></p>
            </li>
            <li data-list-text="">
              <p
                class="s16"
                style="padding-left: 41pt;text-indent: -18pt;line-height: 113%;text-align: left;"
              >
                To request feedback.
                <span class="s14"
                  >We may process your information when necessary to request
                  feedback and to contact you about your use of our
                  Services.</span
                >
              </p>
              <p style="text-indent: 0pt;text-align: left;"><br /></p>
            </li>
            <li data-list-text="">
              <p
                class="s16"
                style="padding-left: 41pt;text-indent: -18pt;line-height: 113%;text-align: left;"
              >
                To identify usage trends.
                <span class="s14"
                  >We may process information about how you use our Services to
                  better understand how they are being used so we can improve
                  them.</span
                >
              </p>
              <p style="text-indent: 0pt;text-align: left;"><br /></p>
            </li>
            <li data-list-text="">
              <p
                class="s16"
                style="padding-left: 41pt;text-indent: -18pt;line-height: 113%;text-align: left;"
              >
                To save or protect an individual&#39;s vital interest.
                <span class="s14"
                  >We may process your information when necessary to save or
                  protect an individual’s vital interest, such as to prevent
                  harm.</span
                >
              </p>
              <p style="text-indent: 0pt;text-align: left;"><br /></p>
              <p
                class="s11"
                style="padding-left: 5pt;text-indent: 0pt;text-align: left;"
              >
                WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
              </p>
              <p style="text-indent: 0pt;text-align: left;"><br /></p>
              <p
                class="s11"
                style="padding-left: 5pt;text-indent: 0pt;line-height: 114%;text-align: left;"
              >
                In Short:
                <i
                  >We only process your personal information when we believe it
                  is necessary and we have a valid legal reason (i.e., legal
                  basis) to do so under applicable law, like with your consent,
                  to comply with laws, to provide you with services to enter
                  into or fulfill our contractual obligations, to protect your
                  rights, or to fulfill our legitimate business interests.</i
                >
              </p>
              <p style="text-indent: 0pt;text-align: left;"><br /></p>
              <h4 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
                Log Data
              </h4>
              <p style="text-indent: 0pt;text-align: left;"><br /></p>
              <p
                class="s9"
                style="padding-left: 5pt;text-indent: 0pt;text-align: left;"
              >
                We want to inform you that whenever you use our Service, in a
                case of an error in the app we collect data and information
                (through third-party products) on your phone
              </p>
              <p
                class="s9"
                style="padding-top: 3pt;padding-left: 5pt;text-indent: 0pt;text-align: left;"
              >
                called Log Data. This Log Data may include information such as
                your device Internet Protocol (“IP”) address, device name,
                operating system version, the configuration of the app when
                utilizing our Service, the time and date of your use of the
                Service, and other statistics.
              </p>
              <p style="text-indent: 0pt;text-align: left;"><br /></p>
              <h4 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
                Cookies
              </h4>
              <p style="text-indent: 0pt;text-align: left;"><br /></p>
              <p
                class="s9"
                style="padding-left: 5pt;text-indent: 0pt;text-align: left;"
              >
                Cookies are files with a small amount of data that are commonly
                used as anonymous unique identifiers. These are sent to your
                browser from the websites that you visit and are stored on your
                device&#39;s internal memory.
              </p>
              <p style="text-indent: 0pt;text-align: left;"><br /></p>
              <p
                class="s9"
                style="padding-left: 5pt;text-indent: 0pt;text-align: left;"
              >
                This Service does not use these “cookies” explicitly. However,
                the app may use third-party code and libraries that use
                “cookies” to collect information and improve their services. You
                have the option to either accept or refuse these cookies and
                know when a cookie is being sent to your device. If you choose
                to refuse our cookies, you may not be able to use some portions
                of this Service.
              </p>
              <p style="text-indent: 0pt;text-align: left;"><br /></p>
              <h4 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
                Service Providers
              </h4>
              <p style="text-indent: 0pt;text-align: left;"><br /></p>
              <p
                class="s9"
                style="padding-left: 5pt;text-indent: 0pt;text-align: left;"
              >
                We may employ third-party companies and individuals due to the
                following reasons:
              </p>
              <p style="text-indent: 0pt;text-align: left;"><br /></p>
            </li>
            <li data-list-text="">
              <p
                class="s9"
                style="padding-left: 41pt;text-indent: -18pt;text-align: left;"
              >
                To facilitate our Service;
              </p>
            </li>
            <li data-list-text="">
              <p
                class="s9"
                style="padding-top: 2pt;padding-left: 41pt;text-indent: -18pt;text-align: left;"
              >
                To provide the Service on our behalf;
              </p>
            </li>
            <li data-list-text="">
              <p
                class="s9"
                style="padding-top: 2pt;padding-left: 41pt;text-indent: -18pt;text-align: left;"
              >
                To perform Service-related services; or
              </p>
            </li>
            <li data-list-text="">
              <p
                class="s9"
                style="padding-top: 2pt;padding-left: 41pt;text-indent: -18pt;text-align: left;"
              >
                To assist us in analyzing how our Service is used.
              </p>
            </li>
          </ul>
          <p style="text-indent: 0pt;text-align: left;"><br /></p>
          <p
            class="s9"
            style="padding-left: 5pt;text-indent: 0pt;text-align: left;"
          >
            We want to inform users of this Service that these third parties
            have access to their Personal Information. The reason is to perform
            the tasks assigned to them on our behalf. However, they are
            obligated not to disclose or use the information for any other
            purpose.
          </p>
          <p style="text-indent: 0pt;text-align: left;"><br /></p>
          <h4 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
            Security
          </h4>
          <p style="text-indent: 0pt;text-align: left;"><br /></p>
          <p
            class="s9"
            style="padding-left: 5pt;text-indent: 0pt;text-align: left;"
          >
            We value your trust in providing us your Personal Information, thus
            we are striving to use commercially acceptable means of protecting
            it. But remember that no method of transmission over the internet,
            or method of electronic storage is 100% secure and reliable, and we
            cannot guarantee its absolute security.
          </p>
          <p style="text-indent: 0pt;text-align: left;"><br /></p>
          <h4 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
            Links to Other Sites
          </h4>
          <p style="text-indent: 0pt;text-align: left;"><br /></p>
          <p
            class="s9"
            style="padding-left: 5pt;text-indent: 0pt;text-align: left;"
          >
            This Service may contain links to other sites. If you click on a
            third-party link, you will be directed to that site. Note that these
            external sites are not operated by us.
          </p>
          <p
            class="s9"
            style="padding-top: 3pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;"
          >
            Therefore, we strongly advise you to review the Privacy Policy of
            these websites. We have no control over and assume no responsibility
            for the content, privacy policies, or practices of any third-party
            sites or services.
          </p>
          <p style="text-indent: 0pt;text-align: left;"><br /></p>
          <h4 style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">
            Children’s Privacy
          </h4>
          <p style="text-indent: 0pt;text-align: left;"><br /></p>
          <p
            class="s9"
            style="padding-left: 5pt;text-indent: 0pt;text-align: left;"
          >
            These Services do not address anyone under the age of 13. We do not
            knowingly collect personally identifiable information from children
            under 13 years of age. In the case we discover that a child under 13
            has provided us with personal information, we immediately delete
            this from our servers. If you are a parent or guardian and you are
            aware that your child has provided us with personal information,
            please contact us so that we will be able to do the necessary
            actions.
          </p>
          <p style="text-indent: 0pt;text-align: left;"><br /></p>
          <h4 style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">
            Changes to This Privacy Policy
          </h4>
          <p style="text-indent: 0pt;text-align: left;"><br /></p>
          <p
            class="s9"
            style="padding-left: 5pt;text-indent: 0pt;text-align: left;"
          >
            We may update our Privacy Policy from time to time. Thus, you are
            advised to review this page periodically for any changes. We will
            notify you of any changes by posting the new Privacy Policy on this
            page.
          </p>
          <p style="text-indent: 0pt;text-align: left;"><br /></p>
          <p
            class="s9"
            style="padding-left: 5pt;text-indent: 0pt;text-align: left;"
          >
            This policy is effective as of 2022-10-29
          </p>
          <p style="text-indent: 0pt;text-align: left;"><br /></p>
          <h4 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
            Contact Us
          </h4>
          <p style="text-indent: 0pt;text-align: left;"><br /></p>
          <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">
            <a href="mailto:support@pahukama.com" class="a" target="_blank"
              >If you have any questions or suggestions about our Privacy
              Policy, do not hesitate to contact us at </a
            ><a href="mailto:support@pahukama.com" target="_blank"
              >support@pahukama.com.</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HowTo",
};
</script>
<style lang="css" scoped></style>
